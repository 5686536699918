@import '/theme.css';

.container {
  position: relative;
  background: var(--color-yellow);
  height: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 15px;

  @media (--viewport-m) {
    padding: 20px;
  }
}

.content {
  width: 100%;
  text-align: right;
}

.txt {
  white-space: nowrap;
}

.link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.social {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 15px;

  @media (--viewport-m) {
    padding: 20px;
  }
}

.linkedIn,
.github {
  height: 20px;
  width: 20px;
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 10px;
}

.github {
  background-image: url('./github.svg');
}

.linkedIn {
  background-image: url('./linkedIn.svg');
}
