@import '/theme.css';

.outer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: relative;
  width: 100vw;
  height: 100%;
  will-change: width, height;
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
}
