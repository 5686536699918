@import '/theme.css';

body,
html {
  font-family: 'Open Sans', sans-serif;
  color: var(--color-gray);
  font-size: 14px;
  height: 100%;

  @media (--viewport-m) {
    font-size: 20px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: var(--color-gray);
}

h1 {
  font-family: 'Josefin Sans', sans-serif;
}

.container {
  overflow: hidden;
  height: 100%;
  & > div {
    height: 100%;
  }
}
